import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Swiper from "swiper/bundle";

export default function Home() {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    $(".item").click(function () {
      $(this).addClass("active").siblings(".item").removeClass("active");
      $(this)
        .next()
        .show()
        .animate({ width: "36%" })
        .siblings(".info")
        .animate({ width: 0 }, function () {
          $(this).hide();
        });
    });

    const swiper = new Swiper(".swiper-container", {
      loop: true,
      slidesPerView: 3,
      // centeredSlides: true,
      effect: "spring", 
      mousewheel: true,
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // nextButton: ".swiper-button-next",
      // prevButton: ".swiper-button-prev",
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        760: {
          slidesPerView: 1,
        },
        // when window width is >= 320px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
        1024: {
          slidesPerView: 3,
        },
      },
    });

    // setTimeout(() => {
    //     setLoader(false);
    // }, 4800);
  }, []);
  const openCity = (evt, cityName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  };
  return (
    <>
      <Helmet>
        <title>Game District Home - Game District</title>
        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        {/* <script src="assets/slider/jquery.min.js"></script> */}
        <script
          src="https://code.jquery.com/jquery-3.7.1.slim.min.js"
          integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8="
          crossorigin="anonymous"
        ></script>
        <script src="assets/js/wow.min.js"></script>
        {/* <script src="assets/slider/jquery.min.js"></script> */}
        {/* <link rel="stylesheet" href="assets/slider/owl.carousel.min.css" />
        <link rel="stylesheet" href="assets/slider/owl.theme.default.min.css" /> */}

        {/* <script src="assets/js/jquery.plate.js"></script> */}
        {/* <script src="assets/slider/owl.carousel.js"></script> */}
        {/* <script src="assets/slider/jquery.mousewheel.min.js"></script> */}
        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
          function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
          setTimeout(() => {
            new WOW().init();
var TxtType = function (el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = "";
  this.tick();
  this.isDeleting = false;
};

TxtType.prototype.tick = function () {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span className="wrap">' + this.txt + "</span>";

  var that = this;
  var delta = 200 - Math.random() * 100;

  if (this.isDeleting) {
    delta /= 2;
  }

  if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
  } else if (this.isDeleting && this.txt === "") {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
  }

  setTimeout(function () {
    that.tick();
  }, delta);
};


  var elements = document.getElementsByClassName("typewrite");
  for (var i = 0; i < elements.length; i++) {
    var toRotate = elements[i].getAttribute("data-type");
    var period = elements[i].getAttribute("data-period");
    if (toRotate) {
      new TxtType(elements[i], JSON.parse(toRotate), period);
    }
  }
  // INJECT CSS
  var css = document.createElement("style");
  css.type = "text/css";
  css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #000}";
  document.body.appendChild(css);

// var owl = $(".owl-carousel");
// owl.owlCarousel({
//   loop: true,
//   nav: false,
//   dots: false,
//   margin: 25,
//   items: 4,
//   responsive: {
//     0: {
//       items: 1,
//     },
//     600: {
//       items: 2,
//     },
//     960: {
//       items: 2,
//     },
//     1200: {
//       items: 3,
//     },
//   },
// });
// owl.on("mousewheel", ".owl-stage", function (e) {
//   if (e.deltaY > 0) {
//     owl.trigger("prev.owl");
//   } else {
//     owl.trigger("next.owl");
//   }
//   e.preventDefault();
// });

fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
const animateTextFromBottom = gsap.utils.toArray(".animate-text-from-bottom");
		animateTextFromBottom.forEach((item) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
			const animateTextFromBottomSplitted = new SplitText(item, { type: "chars, words, lines" });
			animateTextFromBottomSplitted.split({ type: "words" });
			tl.from(animateTextFromBottomSplitted.words, {
				duration: 1,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
				onComplete: () => {
					animateTextFromBottomSplitted.revert();
				},
			});
		});

        const imageParallax = document.querySelectorAll(".parallax-image");

		if (imageParallax.length > 0) {
			imageParallax.forEach(function (element) {
				const wrapper = document.createElement("div");
				wrapper.className = "parallax-image-wrap";

				const innerWrapper = document.createElement("div");
				innerWrapper.className = "parallax-image-inner";

				element.parentNode.insertBefore(wrapper, element);
				wrapper.appendChild(innerWrapper);
				innerWrapper.appendChild(element);

				wrapper.style.overflow = "hidden";

				const animImageParallax = element;
				const imgParallaxWrapper = wrapper;
				const innerWrap = innerWrapper;

				const tlImageParallax = gsap.timeline({
					scrollTrigger: {
						trigger: imgParallaxWrapper,
						start: "top bottom",
						end: "bottom top",
						scrub: true,
						onEnter: animImgParallaxRefresh,
					},
				});

				tlImageParallax.to(animImageParallax, {
					yPercent: 35,
					ease: "none",
				});

				// Function to refresh the scroll trigger
				function animImgParallaxRefresh() {
					tlImageParallax.scrollTrigger.refresh();
				}

				// Animation setup for zoom-in effect
				const tlZoomIn = gsap.timeline({
					scrollTrigger: {
						trigger: imgParallaxWrapper,
						start: "top 100%",
					},
				});

				tlZoomIn.from(innerWrap, {
					duration: 1.5,
					opacity: 0,
					scale: 1.2,
					ease: "power2.out",
					clearProps: "all",
				});
			});
		}
        const text = document.getElementById('text')
        const rotate = new CircleType(text).radius(50)
        text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'
      
          },5000)

    `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
        `}</style>
      </Helmet>
      {loader ? (
        <div className="loaderLayout">
          <img alt="" src="assets/images/logo.png" className="loaderLogo" />
        </div>
      ) : null}
      <div className="top_home_area">
        <Header />
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="wow fadeInUp" data-wow-delay="0.3s">
                  Fastest
                  <span
                    className="star wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                  >
                    <img alt="" src="assets/images/star.png" />
                  </span>
                  Growing
                  <img
                    className="growing"
                    alt=""
                    src="assets/images/growing.png"
                  />
                </h2>
                <h1 className="wow fadeInUp" data-wow-delay="0.5s">
                  <span>
                    <img className="box" alt="" src="assets/images/box.png" />
                  </span>
                  Game Clan In MENAP
                  <img alt="" src="assets/images/menap.png" className="menap" />
                </h1>
              </div>
            </div>

            <div className="row destop_view">
              <div className="col-12">
                <div className="publish">
                  <div
                    className="employees wow bounceInLeft"
                    data-wow-delay="0.8s"
                  >
                    <h3>500+</h3>
                    <p>Employees</p>
                  </div>
                  <div
                    className="advertising_partners wow bounceInLeft"
                    data-wow-delay="0.5s"
                  >
                    <h3>20+</h3>
                    <p>Advertising Partners</p>
                  </div>

                  <div className="downloads2">
                    {loader ? null : (
                      <div className="beating">
                        <a
                          href="#"
                          className="typewrite"
                          data-period="2000"
                          data-type='[ "We’re aggressively beating this number!", "I am Creative.", "I Love Design.", "I Love to Develop."]'
                        >
                          <span className="wrap"></span>
                        </a>
                      </div>
                    )}
                    <img
                      className="downloads_dots"
                      alt=""
                      src="assets/images/downloads_dots.png"
                    />

                    <div
                      className="downloads wow bounceInUp"
                      data-wow-delay="0.2s"
                    >
                      <img alt="" src="assets/images/billion.png" />
                      <h2>BILLION</h2>
                      <p>Downloads</p>
                    </div>
                  </div>
                  <div
                    className="games_published wow bounceInRight"
                    data-wow-delay="0.5s"
                  >
                    <h3>800+</h3>
                    <p>
                      Games
                      <br />
                      Published
                    </p>
                  </div>
                  <div className="dau wow bounceInRight" data-wow-delay="0.8s">
                    <h3>7M+</h3>
                    <p>DAU</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mobile_view">
              <div className="col-12">
                <div className="publish">
                  <div className="downloads2">
                    <div className="beating">
                      <a
                        href="#"
                        className="typewrite"
                        data-period="2000"
                        data-type='[ "We’re aggressively beating this number!", "I am Creative.", "I Love Design.", "I Love to Develop." ]'
                      >
                        <span className="wrap"></span>
                      </a>
                    </div>
                    <img
                      className="downloads_dots"
                      alt=""
                      src="assets/images/downloads_dots.png"
                    />

                    <div
                      className="downloads wow bounceInUp"
                      data-wow-delay="0.2s"
                    >
                      <img alt="" src="assets/images/billion.png" />
                      <h2>BILLION</h2>
                      <p>Downloads</p>
                    </div>
                  </div>

                  <div className="publish_employees">
                    <div
                      className="employees wow bounceInLeft"
                      data-wow-delay="0.8s"
                    >
                      <h3>500+</h3>
                      <p>Employees</p>
                    </div>
                    <div
                      className="advertising_partners wow bounceInLeft"
                      data-wow-delay="0.5s"
                    >
                      <h3>20+</h3>
                      <p>Advertising Partners</p>
                    </div>

                    <div
                      className="games_published wow bounceInRight"
                      data-wow-delay="0.5s"
                    >
                      <h3>800+</h3>
                      <p>
                        Games
                        <br />
                        Published
                      </p>
                    </div>
                    <div
                      className="dau wow bounceInRight"
                      data-wow-delay="0.8s"
                    >
                      <h3>7M+</h3>
                      <p>DAU</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="our_games">
        <div className="container-fluid">
          <div className="row destop_view">
            <div className="col-4">
              <div className="left_area">
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.1s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_1.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.2s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_2.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_3.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_4.png"
                  />
                </div>
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.2s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_5.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_6.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_7.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_8.png"
                  />
                </div>
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_9.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_10.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_11.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_12.png"
                  />
                </div>
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_13.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_14.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_15.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.7s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_16.png"
                  />
                </div>
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_17.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_18.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.7s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_19.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.8s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_20.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="center_area">
                <div className="title">
                  <h1>Our</h1>
                  <div className="icons">
                    <a href="#">
                      <img alt="" src="assets/images/google-play.png" />
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/app-store.png" />
                    </a>
                  </div>
                </div>
                <h2>Awesome</h2>
                <h3>
                  <img
                    className="game-title-icon"
                    alt=""
                    src="assets/images/game-title-icon.png"
                  />
                  Games
                </h3>
                <p>
                  Casual puzzles to epic adventures, discover your next mobile
                  obsession. We craft unforgettable experiences for every
                  player.
                </p>
                <ul className="games_type">
                  <li>
                    <a href="#">Action</a>
                  </li>
                  <li>
                    <a href="#">Strategy</a>
                  </li>
                  <li>
                    <a href="#">Kids</a>
                  </li>
                  <li>
                    <a href="#">Education</a>
                  </li>
                  <li>
                    <a href="#">Adventure</a>
                  </li>
                  <li>
                    <a href="#">Puzzle</a>
                  </li>
                  <li>
                    <a href="#">War</a>
                  </li>
                  <li>
                    <a href="#">Army</a>
                  </li>
                </ul>
                <div className="view_all wow bounceInUp" data-wow-delay="0.2s">
                  <a href="#">View All</a>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="right_area">
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.1s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_1.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.2s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_2.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_3.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_4.png"
                  />
                </div>
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.2s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_5.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_6.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_7.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_8.png"
                  />
                </div>
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_9.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_10.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_11.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_12.png"
                  />
                </div>
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_13.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_14.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_15.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.7s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_16.png"
                  />
                </div>
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_17.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_18.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.7s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_19.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.8s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_20.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mobile_view">
            <div className="col-4">
              <div className="center_area">
                <div className="title">
                  <h1>Our </h1>
                  <div className="icons">
                    <a href="#">
                      <img alt="" src="assets/images/google-play.png" />
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/app-store.png" />
                    </a>
                  </div>
                </div>
                <h2>Awesome</h2>
                <h3>
                  <img
                    className="game-title-icon"
                    alt=""
                    src="assets/images/game-title-icon.png"
                  />
                  Games
                </h3>
                <p>
                  Casual puzzles to epic adventures, discover your next mobile
                  obsession. We craft unforgettable experiences for every
                  player.
                </p>
                <ul className="games_type">
                  <li>
                    <a href="#">Action</a>
                  </li>
                  <li>
                    <a href="#">Strategy</a>
                  </li>
                  <li>
                    <a href="#">Kids</a>
                  </li>
                  <li>
                    <a href="#">Education</a>
                  </li>
                  <li>
                    <a href="#">Adventure</a>
                  </li>
                  <li>
                    <a href="#">Puzzle</a>
                  </li>
                  <li>
                    <a href="#">War</a>
                  </li>
                  <li>
                    <a href="#">Army</a>
                  </li>
                </ul>
                <div className="view_all wow bounceInUp" data-wow-delay="0.2s">
                  <a href="#">View All</a>
                </div>
              </div>
            </div>
            <div className="col-4">
              <p>
                <br />
              </p>
              <div className="left_area">
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.1s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_1.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.2s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_2.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_3.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_4.png"
                  />
                </div>
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.2s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_5.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_6.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_7.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_8.png"
                  />
                </div>
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_9.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_10.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_11.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_12.png"
                  />
                </div>
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_13.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_14.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_15.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.7s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_16.png"
                  />
                </div>
                <div className="games_icon_row">
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_17.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_18.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.7s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_19.png"
                  />
                  <img
                    className="wow bounceInLeft"
                    data-wow-delay="0.8s"
                    alt=""
                    src="assets/images/games_icon/our_games_icon_20.png"
                  />
                </div>
              </div>
              <div className="right_area">
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.1s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_1.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.2s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_2.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_3.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_4.png"
                  />
                </div>
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.2s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_5.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_6.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_7.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_8.png"
                  />
                </div>
                <div className="games_icon_row_right ">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.3s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_9.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_10.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_11.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_12.png"
                  />
                </div>
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.4s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_13.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_14.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_15.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.7s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_16.png"
                  />
                </div>
                <div className="games_icon_row_right">
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_17.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.6s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_18.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.7s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_19.png"
                  />
                  <img
                    className="wow bounceInRight"
                    data-wow-delay="0.8s"
                    alt=""
                    src="assets/images/games_icon/our_games_right_icon_20.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="work_with">
        <div className="content_creators">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="leaves">
                  <img alt="" src="assets/images/leaves.png" />
                </div>
                <div className="love_to_work">
                  <img
                    alt=""
                    src="assets/images/icon-content.png"
                    className="icon-content"
                  />
                  <h2>We love to work with IPs & Content creators</h2>
                  <p>
                    We're passionate about crafting immersive experiences that
                    capture hearts and minds. Whether you're a seasoned game
                    developer or a budding creator with a brilliant idea, we
                    offer everything you need to bring your IP game to life.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="parallax-image-inner">
                  <img
                    className="content_creators_icon parallax-image"
                    alt=""
                    src="assets/images/content_creators_icon.png"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="work_services_top">
                <div className="col-4 fadein_bottom_23">
                  <div className="work_services">
                    <div className="icons">
                      <img alt="" src="assets/images/gameverse-icon.png" />
                    </div>
                    <h3>Forge Your Gameverse</h3>
                    <p>
                      Have a groundbreaking IP idea brewing? We'll guide you
                      through concept development, design, and everything in
                      between to forge your own gameverse. Unleash your
                      creativity and build a world of your own.
                    </p>
                    <div className="learn_more">
                      <a href="#">Learn More</a>
                    </div>
                  </div>
                </div>
                <div className="col-4 fadein_bottom_23">
                  <div className="work_services">
                    <div className="icons">
                      <img alt="" src="assets/images/level-up-icon.png" />
                    </div>
                    <h3>
                      Level Up <br />
                      your IP
                    </h3>
                    <p>
                      You have an Existing IP We'll help you scale it to new
                      heights. From innovative game design to powerful marketing
                      strategies, we'll take your IP to the next level. Dominate
                      the playing field with expert scaling solutions.
                    </p>
                    <div className="learn_more">
                      <a href="#">Learn More</a>
                    </div>
                  </div>
                </div>
                <div className="col-4 fadein_bottom_23">
                  <div className="work_services">
                    <div className="icons">
                      <img alt="" src="assets/images/monetize-icon.png" />
                    </div>
                    <h3>Monetize Your Masterpiece</h3>
                    <p>
                      Storytellers, rejoice! Transform your characters and
                      worlds into captivating IP games. We'll bridge the gap
                      between content creation and game development, helping you
                      monetize your masterpiece. Turn your passion into profit.
                    </p>
                    <div className="learn_more">
                      <a href="#">Learn More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clr"></div>
            </div>
            <div className="clr"></div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="growthsandbox">
        <div className="growth_sand_box">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="growthsandbox-icon">
                  <img alt="" src="assets/images/growthsandbox-icon.png" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="content_growth_sand_box">
                  <img alt="" src="assets/images/growthsandbox.png" />
                  <h3>
                    Growth
                    <br />
                    sandbox
                  </h3>
                </div>
              </div>
              <div className="col-6">
                <div className="fuel_growth">
                  <p className="animate-text-from-right">
                    Fuel growth through intelligent insights. Our cutting-edge
                    analytics unveil the engagement drivers within the game,
                    while dedicated programs empower our teams to reach their
                    full potential.
                    <br />
                    <br />
                    Additionally, we champion the success of fellow game makers
                    through collaborative platforms, offering seamless
                    publishing and launch support.
                  </p>
                  <ul className="games_type">
                    <li>Tools</li>
                    <li>Growth venture</li>
                    <li>Programs</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="tools">
                  <div className="tools_inner">
                    <h3>Unleash the power of data-driven insights.</h3>
                    <p>
                      Our proprietary analytics tools provide deep dives into
                      user behaviour, revealing everything from the most
                      captivating elements to areas ripe for optimisation.
                    </p>
                    <div className="view_tools">
                      <a href="#">View Tools</a>
                    </div>
                    <img alt="" src="assets/images/insights.png" />
                  </div>
                  <div className="clr"></div>
                </div>
              </div>
              <div className="col-6">
                <div className="pixls">
                  <div className="pixls_inner">
                    <h3>Let’s Level up</h3>
                    <p>Fuel your passion, ignite your career.</p>
                    <div className="emplore_more">
                      <a href="#">Emplore More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="gd_hub">
                <div className="col-5">
                  <div className="gd_hub_content fadein_bottom_8">
                    <img alt="" src="assets/images/gd-hub.png" />
                    <h3>
                      Create together!
                      <br />
                      Grow together!
                    </h3>
                    <p>
                      This is the motto we live by. We mash up collaborations,
                      data analytics, and creativity to build joyful experiences
                      that entertain the world!
                    </p>
                    <div className="learn_more">
                      <a href="#">Learn More</a>
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <div className="map">
                    <img
                      className="locations_img"
                      alt=""
                      src="assets/images/locations.png"
                    />
                  </div>
                </div>
                <div className="clr"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="gd_created animate-text-from-bottom">
                  <h4>Game District was created because</h4>
                  <h2>
                    We wanted to empower people and companies to unleash their
                    full potential.
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mission_line_full_top">
                <div className="mission_line_full">
                  <svg
                    width="100%"
                    height="576"
                    viewBox="0 0 1920 576"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-80.9422 574.403C-80.4175 551.424 -66.6231 535.393 -51.4547 518.97C-11.2431 475.43 41.0238 446.457 95.3806 424.093C155.698 399.276 221.973 386.506 287.347 381.839C373.333 375.7 459.338 390.636 543.057 407.012C615.415 421.166 687.841 434.54 760.788 445.333C851.023 458.683 942.244 471.793 1033.61 463.577C1109 456.797 1182.13 435.694 1246.8 395.532C1281.48 373.993 1344.79 322.326 1323.72 273.018C1310.99 243.233 1272.05 223.941 1242.5 220.5C1213.78 217.155 1161.15 221.3 1145 253.5C1134.02 275.398 1155.03 303.562 1174.02 313.071C1214.55 333.373 1285.39 329.904 1324.08 314.209C1378.9 291.966 1411.02 238.412 1452.79 197.135C1482.34 167.931 1509.81 136.704 1541.97 110.202C1578.63 80.0023 1618.84 55.2803 1662.83 37.2602C1763.68 -4.05409 1886.93 -20.4307 1978.14 49.6929"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      className="svg-elem-1"
                    ></path>
                  </svg>
                </div>

                <div className="col-6">
                  <div className="mission">
                    <div className="mission_inner">
                      <div className="mission_icom">
                        <span>
                          We empower small startups to turn Into big
                          enterprises.
                        </span>
                        <img alt="" src="assets/images/mission_icon.png" />
                      </div>
                      <h2>
                        <span>Mission</span>
                        <img alt="" src="assets/images/star_mission.png" />
                      </h2>
                      <p>
                        We provide solutions to increase your ROI, Brand
                        Visibility, Business Scalability, Economic Predictions,
                        Accelerate, Incubate,Invest and impart innovative
                        strategies to sustain your business model.
                      </p>
                      <div className="more_about_us">
                        <a href="#">More ABout us</a>
                      </div>
                      <div className="clr"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="vision">
                    <div className="vision_inner">
                      <div className="vision_icom">
                        <span>We give wings to your dreams.</span>
                        <img alt="" src="assets/images/vision_icon.png" />
                      </div>
                      <h2>
                        <span>Vision</span>
                        <img alt="" src="assets/images/star_mission.png" />
                      </h2>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <p>
                        <br />
                      </p>
                      <div className="more_about_us">
                        <a href="#">More ABout us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="ceo_ara">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="ceo_pic">
                <div className="parallax-image-inner">
                  <img
                    alt=""
                    src="assets/images/ceo-saad-hameed.png"
                    className="parallax-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="message">
                <h2>
                  <img alt="" src="assets/images/message_icon.png" />
                  Message
                  <br />
                  From CEO
                </h2>
                <h6 className="wow fadeInUp" data-wow-delay="0.3s">
                  Game district, where innovation meets immersion.
                </h6>
                <p className="wow fadeInUp" data-wow-delay="0.3s">
                  As the CEO, I'm thrilled to lead a team, passionate about
                  crafting extraordinary gaming experiences that resonate
                  globally. At Game District, we're not just developing games;
                  we're building worlds that captivate and inspire. Our
                  commitment to pushing boundaries and embracing technology
                  ensures we remain at the forefront of the gaming industry. To
                  our incredible community of gamers, partners and enthusiasts,
                  your passion fuels our creativity.
                </p>
                <p className="wow fadeInUp" data-wow-delay="0.5s">
                  Thank you for being part of the Game District journey – where
                  every pixel, every line of code, and every storyline is
                  crafted with you in mind.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>

        <div className="blog_area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>
                  <img
                    className="icon_left"
                    alt=""
                    src="assets/images/blog-title-icon.png"
                  />
                  <span>Latest</span>
                  <img
                    className="icon_right"
                    alt=""
                    src="assets/images/blog-title-icon-right.png"
                  />
                </h2>
                <p>
                  <b>Lorem Ipsum is simply dummy text</b> of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since.
                </p>
                <ul className="post_type">
                  <li>
                    <a
                      className="tablinks active"
                      onClick={(event) => openCity(event, "blogs")}
                      //   onclick="openCity(event, 'blogs')"
                    >
                      Blogs
                    </a>
                  </li>
                  <li>
                    <a
                      className="tablinks"
                      //   onclick="openCity(event, 'interviews')"
                      onClick={(event) => openCity(event, "interviews")}
                    >
                      Interviews
                    </a>
                  </li>
                  <li>
                    <a
                      className="tablinks"
                      //   onclick="openCity(event, 'socialmedia')"
                      onClick={(event) => openCity(event, "socialmedia")}
                    >
                      Social Media
                    </a>
                  </li>
                  <li>
                    <a
                      className="tablinks"
                      //   onclick="openCity(event, 'events')"
                      onClick={(event) => openCity(event, "events")}
                    >
                      Events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clr"></div>
            <div id="blogs" className="tabcontent" style={{ display: "block" }}>
              <div className="row">
                <div className="col-12">
                  <div className="blog_slide">
                    {/* <div className="nonloop owl-carousel owl-theme"> */}
                    <div className="swiper-container">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide c1">
                          {/* <div className="item"> */}
                          <div className="home_blog_post post_bg1">
                            <img alt="" src="assets/images/blog-post-1.png" />
                            <div className="post_date">12 March 2024</div>
                            <h3>Game District Vanguard Singapore Edition</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                        <div className="swiper-slide c1">
                          <div className="home_blog_post post_bg2">
                            <img alt="" src="assets/images/blog-post-2.png" />
                            <div className="post_date">8 March 2024</div>
                            <h3>Our Wonder Women - Women’s Day</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide c1">
                          <div className="home_blog_post post_bg1">
                            <img alt="" src="assets/images/blog-post-3.png" />
                            <div className="post_date">20 Feb 2024</div>
                            <h3>Game District Warriors - Techleague</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide c1">
                          <div className="home_blog_post post_bg2">
                            <img alt="" src="assets/images/blog-post-4.png" />
                            <div className="post_date">10 Feb 2024</div>
                            <h3>
                              White Nights Pakistan <br />
                              Meetup
                            </h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide c1">
                          <div className="home_blog_post post_bg2">
                            <img alt="" src="assets/images/blog-post-2.png" />
                            <div className="post_date">8 March 2024</div>
                            <h3>Our Wonder Women - Women’s Day</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide c1">
                          <div className="home_blog_post post_bg1">
                            <img alt="" src="assets/images/blog-post-3.png" />
                            <div className="post_date">20 Feb 2024</div>
                            <h3>Game District Warriors - Techleague</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </div>

            <div id="interviews" className="tabcontent">
              <div className="row">
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-1.png" />
                    <div className="post_date">12 March 2024</div>
                    <h3>Game District Vanguard Singapore Edition</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg2">
                    <img alt="" src="assets/images/blog-post-2.png" />
                    <div className="post_date">8 March 2024</div>
                    <h3>Our Wonder Women - Women’s Day</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-3.png" />
                    <div className="post_date">20 Feb 2024</div>
                    <h3>Game District Warriors - Techleague</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="socialmedia" className="tabcontent">
              <div className="row">
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-1.png" />
                    <div className="post_date">12 March 2024</div>
                    <h3>Game District Vanguard Singapore Edition</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg2">
                    <img alt="" src="assets/images/blog-post-2.png" />
                    <div className="post_date">8 March 2024</div>
                    <h3>Our Wonder Women - Women’s Day</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="events" className="tabcontent">
              <div className="row">
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-1.png" />
                    <div className="post_date">12 March 2024</div>
                    <h3>Game District Vanguard Singapore Edition</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg2">
                    <img alt="" src="assets/images/blog-post-2.png" />
                    <div className="post_date">8 March 2024</div>
                    <h3>Our Wonder Women - Women’s Day</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-3.png" />
                    <div className="post_date">20 Feb 2024</div>
                    <h3>Game District Warriors - Techleague</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="meet_the_studios">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <img alt="" src="assets/images/meet_the_studios_icon.png" />
                Meet the
                <br />
                Studios
              </h2>
              <p>
                <b>Where passion meets expertise.</b> Discover a collective of
                world-class studios, each dedicated to pushing the boundaries of
                mobile entertainment.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="logo_studios">
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/azel-games.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/play-spare.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/tap-toy.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/northstar.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/rebel-games.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/level-zone.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/stickya-games.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/piggy-panda.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/dead-pixels.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/hmbl.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/omc-games.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/koko-zone.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="game_changers">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <img alt="" src="assets/images/game_changers-con.png" />
                Game changers
              </h2>
              <p>
                Dive into the inspiring journeys of our talented individuals,
                where passion meets purpose, and dreams become reality.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="slider_img_content">
                <div className="item">
                  <img
                    className="pic_lead"
                    alt=""
                    src="assets/images/irfan_ahmad_lead.png"
                  />
                  <img
                    className="pic_lead_active"
                    alt=""
                    src="assets/images/irfan_ahmad_1_lead.png"
                  />
                </div>
                <div className="info">
                  <h3>Irfan Ahmad</h3>
                  <p>Principal digital artist</p>
                  <p>
                    Duaa’s journey to becoming a lead digital artist is filled
                    with hard work, dedication, and a passion for creativity.
                    Starting out as a junior digital artist, she was determined
                    to master the skills necessary to succeed in the competitive
                    world of digital art and design, Duaa spent countless hours
                    practicing and perfecting her craft, constantly seeking out
                    new techniques and software programs to expand her skill
                    set.
                  </p>
                </div>
                <div className="item">
                  <img
                    className="pic_lead"
                    alt=""
                    src="assets/images/usman_qureshi_lead.png"
                  />
                  <img
                    className="pic_lead_active"
                    alt=""
                    src="assets/images/usman_qureshi_1_lead.png"
                  />
                </div>
                <div className="info">
                  <h3>Usman Qureshi</h3>
                  <p>Principal digital artist</p>
                  <p>
                    Duaa’s journey to becoming a lead digital artist is filled
                    with hard work, dedication, and a passion for creativity.
                    Starting out as a junior digital artist, she was determined
                    to master the skills necessary to succeed in the competitive
                    world of digital art and design, Duaa spent countless hours
                    practicing and perfecting her craft, constantly seeking out
                    new techniques and software programs to expand her skill
                    set.
                  </p>
                </div>
                <div className="item active">
                  <img
                    className="pic_lead"
                    alt=""
                    src="assets/images/duaa_lead.png"
                  />
                  <img
                    className="pic_lead_active"
                    alt=""
                    src="assets/images/duaa_1_lead.png"
                  />
                </div>
                <div
                  className="info"
                  style={{ display: "block", width: "36%" }}
                >
                  <h3>Duaa</h3>
                  <p>Principal digital artist</p>
                  <p>
                    Duaa’s journey to becoming a lead digital artist is filled
                    with hard work, dedication, and a passion for creativity.
                    Starting out as a junior digital artist, she was determined
                    to master the skills necessary to succeed in the competitive
                    world of digital art and design, Duaa spent countless hours
                    practicing and perfecting her craft, constantly seeking out
                    new techniques and software programs to expand her skill
                    set.
                  </p>
                </div>
                <div className="item">
                  <img
                    className="pic_lead"
                    alt=""
                    src="assets/images/abubakar-saddique_lead.png"
                  />
                  <img
                    className="pic_lead_active"
                    alt=""
                    src="assets/images/abubakar_saddique_1_lead.png"
                  />
                </div>
                <div className="info">
                  <h3>Abubakar Saddique</h3>
                  <p>Principal digital artist</p>
                  <p>
                    Duaa’s journey to becoming a lead digital artist is filled
                    with hard work, dedication, and a passion for creativity.
                    Starting out as a junior digital artist, she was determined
                    to master the skills necessary to succeed in the competitive
                    world of digital art and design, Duaa spent countless hours
                    practicing and perfecting her craft, constantly seeking out
                    new techniques and software programs to expand her skill
                    set.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>

      <Footer />
    </>
  );
}
